<template>
	<div class="playlistCreate" >

        <VueLoading :active.sync="showLoadingIndicator" :can-cancel="true"></VueLoading>

		<h1 class="text-center">Passt alles?</h1>

		<div v-for="item in sortedChallengeWeeks" v-bind:key="item.id" class="section">
			<div class="section__title" >
				<h4>Woche {{ item.week_number }}</h4>
				<small>{{ calculateStartOfWeek(item.week_number) }} - {{ calculateEndOfWeek(item.week_number) }}</small>
			</div>
            <template v-for="exercise in item.exercises" >
                <ChallengeWeekItem v-if="!update" :weekNumber="item.week_number" :key="exercise.id" :exercise="exercise"/>
            </template>
		</div>
        <ChallengePreInformationModal></ChallengePreInformationModal>
        <SuccessNotificationModal @closeModal="closeModal" title="Challenge gestartet" message="Alle Trainingseinheiten wurden in deinen Trainingsplan importiert."></SuccessNotificationModal>
		<!-- Fitnessplan Detail Modal -->
        <ChangeChallengeModal :is="changeChallengeComponent" @saveNewTime="saveNewTime"/>
		<v-btn class="button button--primary"
               :disabled="isButtonDisabled"
               @click="challengeAccepted">
               Challenge starten
        </v-btn>
	</div>
</template>

<script>
	import axios from "axios";

    import ChallengeWeekItem from "../components/ChallengeWeekItem";
    import ChangeChallengeModal from "@/components/ChangeChallengeModal";
    import SuccessNotificationModal from '@/components/SuccessNotificationModal.vue';
    import ChallengePreInformationModal from '@/components/ChallengePreInformationModal.vue';
    import moment from "moment/moment";
    import { generateGuid } from "@/sportlerplus-helper";

    import VueLoading from 'vue-loading-overlay/dist/vue-loading';
    // Import stylesheet
    import 'vue-loading-overlay/src/css/index.css';
    import {analytics} from "@/firebase";

    export default {
		name: 'FitnessplanChallengeSummary',
        components: {
            ChallengeWeekItem,
            ChangeChallengeModal,
            VueLoading,
            SuccessNotificationModal,
            ChallengePreInformationModal
        },
        data() {
            return {
                startDate: "",
                challenges: [],
                update: false,
                changeChallengeComponent: "ChangeChallengeModal",
                challengeId: null,
                uniqueChallengeId: generateGuid(),
                fitnessPlan: {
                    items: [],
                },
                showLoadingIndicator: false,
                isButtonDisabled: false,
            };
        },
        async mounted() {
            this.challengeId = parseInt(this.$route.query.id);
            this.startDate = moment(this.$route.query.startDate);
            let apiUrl = this.$store.getters.getApiUrl;
            const response = await axios.get(apiUrl + 'pages/?type=fitnessplan.FitnessPlanWeekPage&child_of=' + this.challengeId + "&fields=week_number,exercises");
            this.challenges = response.data.items;
            let idCounter = 0;
            this.$modal.show('challengepreinformation');
            for (let i = 0; i < this.challenges.length; i++) {
                for (let j = 0; j < this.challenges[i].exercises.length; j++) {
                    this.challenges[i].exercises[j].exerciseId = idCounter += 1;
                    this.challenges[i].exercises[j].uniqueChallengeId = this.uniqueChallengeId;
                }
            }
        },
        computed: {
            sortedChallengeWeeks() {
                let challengeWeeks = [...this.challenges];
                return challengeWeeks.sort(function (a, b) { return a.week_number - b.week_number; });
            }
        },
        methods: {
            calculateStartOfWeek(weekNumber) {
                return moment(this.startDate).add(weekNumber * 7 - 7,'days').format('DD.MM');
            },
            calculateEndOfWeek(weekNumber) {
                return moment(this.startDate).add(weekNumber * 7 - 1,'days').format('DD.MM.YYYY');
            },
            saveNewTime(newTime, isForAllFollowingActivities, exerciseId) {
                this.update = true;
                let weekNumber = 0;
                let dayNumber = 0;
                for (let i = 0; i < this.challenges.length; i++) {
                    for (let j = 0; j < this.challenges[i].exercises.length; j++) {
                        if (this.challenges[i].exercises[j].exerciseId === parseInt(exerciseId)) {
                            this.challenges[i].exercises[j].activityTime = newTime;
                            dayNumber = this.challenges[i].exercises[j].day_number;
                            weekNumber = this.challenges[i].week_number;
                        }
                    }
                }
                if (isForAllFollowingActivities) {
                    for (let i = 0; i < this.challenges.length; i++) {
                        if (this.challenges[i].week_number > weekNumber) {
                        for (let j = 0; j < this.challenges[i].exercises.length; j++) {
                                this.challenges[i].exercises[j].activityTime = newTime;
                            this.$forceUpdate();
                            }
                        }
                        else if (this.challenges[i].week_number === weekNumber) {
                            for (let j = 0; j < this.challenges[i].exercises.length; j++)
                            if (this.challenges[i].exercises[j].day_number > dayNumber) {
                                this.challenges[i].exercises[j].activityTime = newTime;
                                this.$forceUpdate();
                            }
                        }
                    }
                }
                this.$nextTick( () => {
                    this.update = false;
                });
            },
            async challengeAccepted() {
                this.isButtonDisabled = true;
                this.showLoadingIndicator = true;
                await this.$store.dispatch('fetchFitnessPlan').then((res) => {
                    if (res.length !== 0) {
                        this.fitnessPlan = res[0];
                    }
                });                
                for (let i = 0; i < this.challenges.length; i++) {
                    for (let j = 0; j < this.challenges[i].exercises.length; j++) {
                        let activityId =  this.challenges[i].exercises[j].exercise.id;
                        let response = await axios.get('https://cms.sportlerplus.com/api/v2/pages/' + activityId + '/');
                        let activity = response.data
                        let event = {
                            weekday: null,
                            repeating: false,
                            repeatInterval: 0,
                            activityTime: this.challenges[i].exercises[j].activityTime,
                            activityDate: this.challenges[i].exercises[j].activityDate,
                            activityEndDate: null,
                            activity: Object.assign({}, activity),
                            guid: generateGuid(),
                            isOptionalExercise: this.challenges[i].exercises[j].optional_exercise,
                            activityState: null,
                            // TODO: @Sven for testing. Delete this after testing
                            //activityState: "finished",
                            challengeId: this.challengeId,
                            uniqueChallengeId: this.uniqueChallengeId,
                            partOfChallenge: this.challenges[i].title,
                        };
                        this.fitnessPlan.items.push(event);
                    }
                }
                this.fitnessPlan.startedChallenges = this.fitnessPlan.startedChallenges  || [];
                this.fitnessPlan.startedChallenges.push({
                    id: this.challengeId,
                    uniqueChallengeId: this.uniqueChallengeId,
                    inProgress: true,
                })
                analytics.logEvent('start_challenge', { challenge_id: this.challengeId });
                this.$store.dispatch('saveFitnessPlan', this.fitnessPlan).then(() => {
                    this.showLoadingIndicator = false;
                    this.$modal.show("successNotification");
                });
            },
            closeModal() {
                this.$router.push("/fitnessplan");
            }
        }
	}
</script>

<style lang="scss" scoped>
	/* TODO: REMOVE CODE IF SCSS FROM PLAYLISTCREATENAME EFFECTS GLOBALLY */
	.playlistCreate {
		display: flex;
		flex-direction: column;
		min-height: 100%;
		padding-bottom: 76px;

		h1 {
			margin-top: 31px;
			margin-bottom: 7px;
		}

		p {
			margin-bottom: 25px;
		}

		.list__item__label {
			font-size: 17px;
		}

		> .button {
			position: fixed;
			z-index: 10;
			bottom: 20px;
			width: calc(100% - 40px);
		}
	}

	.datepicker, .list {
		box-shadow: 0 8px 16px 0 rgba(30,29,31,0.08), inset 0 0 0 1px rgba($colorBlack, .08);
		margin-bottom: 16px;
	}

	.grouped {
		box-shadow: none;
		margin-bottom: -16px;
	}

</style>