<template>
    <!-- Fitnessplan Detail Modal -->
    <VModal name="changeDay" width="100%" height="auto" :shiftY="1" :scrollable="true" class="fitnessplanModal">
        <div class="vm-header">
            <div class="vm-header-left"></div>
            <div class="vm-header-center">
                Uhrzeit ändern
            </div>
            <div class="vm-header-right">
                <img src="@/assets/ic_nav_close.svg" alt="" @click="closeChangeDayModal">
            </div>
        </div>
        <div class="vm-content">
            <p class="text-center">Wähle die Zeit aus, auf die du die Trainingseinheit verlegen möchtest.</p>
            <!-- TODO @Marco: Replace with Datepicker -->
            <input type="time"  v-model="changedTime" style="width: 100%">
            <div class="list list--background">
                <label class="list__item grouped">
                    <div class="list__item__content">
                        <div class="list__item__label">
                            Auch für alle nachfolgenden Trainingstage übernehmen
                        </div>
                    </div>
                    <div class="toggle">
                        <input v-model="isForAllFollowingActivities" type="checkbox">
                        <span class="toggle__button"></span>
                    </div>
                </label>
<!--                <label class="list__item">-->
<!--                    <div class="list__item__content">-->
<!--                        <div class="list__item__label">-->
<!--                            <small>Alle folgenden Einheiten werden ebenfalls nach hinten verschoben, um die Reihenfolge des Plans beizubehalten.</small>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </label>-->
            </div>
            <v-btn class="button button--primary" @click="saveNewTime">Speichern</v-btn>
        </div>
    </VModal>
</template>

<script>
export default {
    name: "ChangeChallengeModal",
    data() {
        return {
            changedTime: "",
            isForAllFollowingActivities: false,
        }
    },
    mounted() {
        this.changedTime = this.$route.query.startTime;
    },
    methods: {
        saveNewTime() {
            const exerciseId = this.$route.query.exerciseId;
            this.$emit("saveNewTime", this.changedTime, this.isForAllFollowingActivities, exerciseId);
            this.$modal.hide('changeDay');
        },
        closeChangeDayModal() {
            this.$router.push("/fitnessplan/challenge/showSummary");
            this.$modal.hide('changeDay');
        },
    },
}
</script>

<style scoped>
    .fitnessplanModal p {
        margin-top: -8px;
        margin-bottom: 19px;
        line-height: 20px;
    }
</style>