<template>
    <VModal @closed="emitClose" name="challengepreinformation" class="notification-modal centered" width="100%" height="auto" :scrollable="true">
      <div class="vm-content">
        <img src="@/assets/notification/ic_notification_list.svg" alt="">
        <h3>
          Deine Challengeeinheiten
        </h3>
        <ul>
            <li>
                Uhrzeiten ändern
                <span>Du kannst die Uhrzeit jedes Trainings individuell anpassen.</span>
            </li>
            <li>
                Trainingstage ändern
                <span>Um die empfohlenen Erholungsphasen und die Reihenfolge beizubehalten, können die Tage nicht im Kalender geändert werden.</span>
            </li>
            <li>
                Einheit an einem anderen Tag absolvieren
                <span>Kein Problem! Du kannst verpasste Einheiten einfach nachholen oder ggf. auch früher als geplant durchführen.</span>
            </li>
        </ul>
        <v-btn type="button" class="button button--primary button--small" @click="dismissDialog">Verstanden</v-btn>
      </div>
    </VModal>
</template>

<script>
    export default {
        name: 'ChallengePreInformationModal',
        /* props: {
            title: {
                type: String,
                default() { return ""; } 
            },
            message: {
                type: String,
                default() { return ""; } 
            }
        }, */
        methods: {
            dismissDialog() {
                this.$modal.hide('challengepreinformation');
            },
            emitClose() {
                this.$emit('closeModal');
            }
        }
    }
</script>

<style lang="scss" scoped>
    @media screen and (max-height: 840px) {
        .notification-modal {
            padding: 24px 0 64px;
        }
    }

    ul {
        text-align: left;
        padding: 0 !important;
        margin: 0 0 13px 0;

        li {
            display: flex;
            flex-direction: column;
            padding: 8px 0 8px 36px;
            position: relative;
            list-style-type: none;
            font-size: 14px;
            letter-spacing: -0.2px;
            font-weight: 600;
            line-height: 20px;
            color: $colorBlack;

            &:before {
                content: '';
                position: absolute;
                top: 4px;
                left: 0;
                width: 24px;
                height: 28px;
                background: url('../assets/ic_premium_check.svg')
            }

            span {
                color: rgba($colorBlack, .48);
                font-weight: 500;
                margin: 6px 0;
            }
        }
    }
</style>