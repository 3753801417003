<template>
    <div >
        <div class="contentItem" @click="openChangeDayModal">
            <div v-if="!isFinished" class="contentItem__icon">
                <div class="contentItem__icon__date">
                    {{ dayOfExercise }}<span>{{ monthOfExercise }}</span>
                </div>
                <img src="@/assets/contentitem/ic_contentitem_empty.svg" alt="">
            </div>
            <div v-else class="contentItem__icon">
                <img src="@/assets/contentitem/ic_contentitem_success.svg" alt="">
            </div>
            <div class="contentItem__content">
                <div class="contentItem__title">
                    {{ exercise.exercise.title }}
                </div>
                <div class="contentItem__meta">
                    <span class="dark">{{ exercise.item_type }}</span>・{{ timeOfExercise }} Uhr・{{ exercise.duration }} Min.
                </div>
            </div>
            <div v-if="!isFinished" class="contentItem__actions">
                <img src="@/assets/ic_edit.svg" alt=""/>
            </div>
            <div class="contentItem__optional" v-if="exercise.optional_exercise">
<!--                <img src="@/assets/ic_16_info.svg" alt=""/>-->
                Diese Einheit ist optional
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "WorkoutWeekItem",
    data() {
      return {
          dateAndTimeOfExercise: "",
          dayOfExercise: "",
          monthOfExercise: "",
          timeOfExercise: "",
          //challengeType: "",
          isFinished: false,
      }
    },
    props: {
        exercise: {
            type: Object,
        },
        weekNumber: {
            type: Number,
        },
    },
    methods: {
        openChangeDayModal() {
            this.$router.push("/fitnessplan/challenge/showSummary?&exerciseId=" + this.exercise.exerciseId +
                "?&startTime=" + moment(this.dateAndTimeOfExercise).format("HH:mm"));
            if(!this.isFinished) {
                this.$modal.show('changeDay');
            }
        },
        checkIsOptionalExercise() {
            if (this.exercise.optional_exercise) {
                return "(optional)";
            }
        },
    },
    mounted() {
        if(!this.exercise.activityDate) {
            const startTime = this.$route.query.startTime;
            const startDate = this.$route.query.startDate;
            this.dateAndTimeOfExercise = moment(startDate + ' ' + startTime).add((this.weekNumber * 7) + this.exercise.day_number - 8, 'days');
            this.dayOfExercise = moment(this.dateAndTimeOfExercise).get('date');
            this.monthOfExercise = moment(this.dateAndTimeOfExercise).locale('de').format('MMM');
            this.timeOfExercise = moment(this.dateAndTimeOfExercise).format('HH:mm')
            this.exercise.activityDate = moment(this.dateAndTimeOfExercise).format('YYYY-MM-DD');
            this.exercise.activityTime = moment(this.dateAndTimeOfExercise).format('HH:mm');
        }
        else {
            this.dateAndTimeOfExercise = moment(this.exercise.activityDate + ' ' + this.exercise.activityTime);
            this.dayOfExercise = moment(this.dateAndTimeOfExercise).get('date');
            this.monthOfExercise = moment(this.dateAndTimeOfExercise).format('MMM');
            this.timeOfExercise = moment(this.dateAndTimeOfExercise).format('HH:mm')
        }
    }
}
</script>

<style lang="scss" scoped>
    .contentItem--finished {
        .contentItem__content {
            opacity: .48;
        }
    }
    .contentItem__icon__date {
        position: absolute;
        top: calc(50% + 1px);
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 14px;
        line-height: 11px;
        font-weight: 700;
        color: $colorWhite;
        text-transform: uppercase;

        span {
            font-size: 8px;
        }
    }

    .contentItem {
        flex-wrap: wrap;
    }

    .contentItem__optional {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        color: rgba($colorBlack, .48);
        width: 100%;
        background: rgba($colorBlack, .04);
        padding: 9px 8px 10px;
        box-shadow: inset 0 1px 0 0 rgba($colorBlack, .04);

        img {
            margin-right: 8px;
        }
    }

    .contentItem__meta {
        white-space: inherit;

        .dark {
            color: $colorBlack;
        }
    }
</style>